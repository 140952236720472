.au-mission-sec {
  padding: 24px 0;

  @include media($md) {
    padding: 50px 0;
  }

  h2 {
    font-weight: 500;
    font-size: 20px;
    color: #333;

    @include media($md) {
      font-size: 22px;
    }

    @include media($lg) {
      font-size: 26px;
      margin-bottom: 40px;
    }
  }

  p {
    // font-size: 20px;
    line-height: 1.5;
    color: #333;

    @include media($sm) {
      font-size: 20px;
    }

    @include media($md) {
      font-size: 24px;
      padding: 0 46px;
      border-left: 7px solid #f1f8fe;
    }

    @include media($lg) {
      font-size: 28px;
    }
  }
}

.faq-sec {
  padding: 24px 0;

  @include media($md) {
    padding: 50px 0;
  }

  h2,
  p {
    color: #333;

    @include media($sm) {
      font-size: 18px;
    }

    @include media($md) {
      font-size: 20px;
    }
  }

  h2 {
    font-size: 16px;
    font-weight: 600;
  }

  p {
    color: #666;
    line-height: 1.5;

    + h2 {
      margin-top: 50px;
    }
  }
}

.navbar-light {
  background-color: $white;
  // -webkit-backdrop-filter: blur(8px);
  // backdrop-filter: blur(8px);
}

.navbar-bom {
  transition: all 0.3s ease-in;
}

.bom-fixed {
  padding-top: 20px;
}

.nav-bom .nav-link {
  font-size: 12px;
  font-weight: normal;
  color: $black;
  position: relative;

  @include media($sm, max) {
    padding-left: 5px;
    padding-right: 5px;
  }

  @include media($lg) {
    font-size: 18px;
  }

  &.active {
    font-weight: bold;
    color: $primary-color;

    // &::after {
    //   content: "";
    //   width: 100%;
    //   height: 2px;
    //   background-color: $primary-color;
    //   position: absolute;
    //   left: 0;
    //   bottom: 0;

    //   @include media($lg) {
    //     bottom: -9px;
    //   }
    // }
  }
}

.navbar-expand-lg .navbar-nav .nav-link {
  @media (min-width: 992px) {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
}

.btn-call {
  color: #fff;
  background-color: var(--black);
  border-color: var(--black);
  border-radius: 6px;
  font-size: 12px;
  font-weight: 500;
  // display: inline-flex;
  // align-items: center;
  // gap: 8px;
  padding: 0.55rem 1rem;
  position: relative;

  @include media($md) {
    font-size: 14px;
    padding: 0.55rem 1.4rem;
    width: 166px;
  }

  @include media($lg) {
    font-size: 18px;
  }

  &:active,
  &:hover {
    color: $white;
    background-color: darken($color: $primary-color, $amount: 1);
    border-color: darken($color: $primary-color, $amount: 1);
  }

  // &::before {
  //   content: "";
  //   width: 13px;
  //   height: 13px;
  //   background: url("../images/calling-icon.svg") center no-repeat;
  //   background-size: contain;
  //   display: inline-block;
  // }

  // &::after {
  //   content: "";
  //   display: inline-block;
  //   position: absolute;
  //   top: 5px;
  //   left: -54px;
  //   width: 44px;
  //   height: 20px;
  //   background: url("../images/red-down-arrow-icon.svg") center no-repeat;
  //   pointer-events: none;
  // }
}

.btn-talk {
  color: $primary-color;
  background-color: var(--white);
  border-color: var(--primary-color);
  margin-left: 10px;

  @include media($md) {
    margin-left: 20px;
  }
}

// .dropdown-scroll {
//   max-height: 210px;
//   overflow-x: auto;

//   /* width */
//   &::-webkit-scrollbar {
//     width: 6px;
//   }

//   /* Track */
//   &::-webkit-scrollbar-track {
//     background: transparent;
//   }

//   /* Handle */
//   &::-webkit-scrollbar-thumb {
//     background: #262e35;
//     border-radius: 4px;
//   }

//   /* Handle on hover */
//   &::-webkit-scrollbar-thumb:hover {
//     background: #262e35;
//   }
// }

// Paths
$font-path: '/fonts';
$icomoon-font-path: '/fonts/icomoon/fonts';
$images-path: '../img';

$primary-color: #0278CA;

$white : #ffffff;
$black: #000000;
$halfBlack: #333333;
$green: #4CAF50;
$red: #bc4545;
$blue: #0278CA;
$lighBlue: #F3F7FD;
$yellow: #ffcc32;
$grey: #6A6A6A;
$silver: #f2f4f6;
$orange: #f25621;
$pink : #ae4b84;




//Breakpoints
//$xs : 767px;
$xsSmall: 360px;
$xxs: 420px;
$xs : 576px; // was 769px but for iPhoneX Landscape it made 815px
$sm: 768px;
$md: 1024px; // was 770px but for iPhoneX Landscape it made 815px
$lg: 1280px;    
$xl: 1366px;
$xxl : 1800px;
:root {
  --orange: #ff5722;
  --blue: #147ec2;
  --bs-font-sans-serif: "Gilroy", sans-serif;
  --primary-color: #0278ca;
  --black: #000000;
  --white: #ffffff;
}

body {
  padding-top: 130px;

  @include media($sm) {
    padding-top: 96px;
  }
}

.container,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  @media (min-width: 1400px) {
    max-width: 1280px;
  }
}

.btn {
  &:focus {
    box-shadow: none;
  }
}

.btn-outline {
  border: 1px solid #ced4da;

  &:hover {
    border-color: $blue;
    color: $blue;
  }
}

.bg-light-blue {
  background-color: #F1F8FE;
}

.text-blue {
  color: $blue;
}

.text-black {
  color: $black;
}

.slick-dots li {
  height: 13px;
  width: auto;
  margin: 0 3px;

  button {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: #293861;
    border: 1px solid #293861;

    &::before {
      display: none;
    }
  }

  &.slick-active button {
    border: 1px solid $primary-color;
    background-color: $primary-color;
    width: 30px;
    border-radius: 5px;
    // margin-right: 10px;
  }
}

.slick-dotted-top {
  .slick-dots {
    bottom: inherit;
    top: -58px;
    text-align: right;
  }
}

.link-text {
  color: $black;

  &:hover {
    color: $blue;
  }
}

.scroll-theme {
  /* width */
  &::-webkit-scrollbar {
    width: 6px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #262e35;
    border-radius: 4px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #262e35;
  }
}

.breadcrumb-item {
  font-size: 0.9em;

  &.active {
    color: $black;
  }

  + .breadcrumb-item {
    display: flex;
    align-items: center;
    gap: 5px;

    &::before {
      content: "";
      width: 4px;
      height: 4px;
      background: $white;
      border: 1px solid #057bcb;
      border-radius: 8px;
      display: inline-block;
      padding-right: 0;
    }
  }

  a {
    color: #0078ca;
    text-decoration: none;
  }
}

.main-title {
  font-weight: 300;
  font-size: 18px;
  color: #0078ca;
  margin-bottom: 15px;

  @include media($sm) {
    font-size: 24px;
  }
  
  @include media($md) {
    font-size: 28px;
  }

  @include media($xl) {
    font-size: 34px;
  }
}

.main-sub-title {
  font-weight: 300;
  font-size: 40px;
  line-height: 1.1;
  color: #000;

  @include media($sm) {
    font-size: 55px;
    line-height: 1.2;
  }
  
  @include media($md) {
    font-size: 70px;
  }

  @include media($xxl) {
    font-size: 80px;
  }
}

.p-text {
  font-size: 16px;
  line-height: 1.5;
  color: #666;  

  @include media($sm) {
    font-size: 18px;
  }

  @include media($md) {
    font-size: 20px;
  }

  &.p-lg-text {
    @include media($lg) {
      font-size: 26px;
    }
  }

  &.grey-text {
    color: #666;
  }
}

ul.green-list {
  padding: 0;
  list-style: none;
  color: #666;

  li {
    position: relative;
    padding-left: 29px;
    margin-bottom: 20px;
    // font-size: 20px;
    line-height: 1.4;

    @include media($sm) {
      font-size: 20px;
    }

    &::before {
      content: "";
      display: inline-block;
      position: absolute;
      top: 2px;
      left: 0;
      width: 19px;
      height: 19px;

      background: url("../images/tick-check.png") center no-repeat;
      background-size: contain;
      

      // @include media($md) {
      //   bottom: -139px;
      //   right: -5px;
      //   width: 110px;
      //   height: 110px;
      // }
    }
  }
}

/* Custom CSS for error message styling */
// .error-message {
//   color: red;
//   font-size: 0.9em;
//   display: none;
// }
// .is-invalid {
//   border-color: red;
// }

.help-us-sec {
  padding: 150px 0 40px;
  background-color: #f1f8fe;

  @include media($md) {
    padding: 120px 0;
  }
}

.huthy-para {
  font-size: 26px;
  font-weight: 500;
  color: $halfBlack;
}

.form-sec {
  .col-form-label {
    font-weight: bold;
  }

  .btn-call {
    background-color: $primary-color;
    border-color: $primary-color;
    width: 100%;

    @include media($md) {
      width: 75%;
    }
  }
}

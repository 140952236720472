.holistic-view-sec {
  //   height: 961.35px;
  background: #071232;
  padding: 60px 0;

  h2 {
    font-weight: 500;
    font-size: 30px;
    text-align: center;
    color: #fff;
    max-width: 892px;
    margin: 0 auto;

    @include media($sm) {
      font-size: 36px;
    }
  }
}

.holistic-view-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;

  // margin-top: 60px;

  .holistic-view-item {
    position: relative;
    &::after {
      content: "";
      display: block;
      width: 14px;
      height: 14px;
      position: absolute;
      top: 50%;
      right: -17px;

      @include media($sm) {
        right: -25px;
      }
    }

    &:nth-child(1) {
      &::after {
        background: url("../images/orange-arrow.svg") no-repeat;
        background-size: contain;
      }
    }

    &:nth-child(2) {
      &::after {
        background: url("../images/blue-arrow.svg") no-repeat;
        background-size: contain;
      }
    }

    &:nth-child(3) {
      &::after {
        background: url("../images/green-arrow.svg") no-repeat;
        background-size: contain;
      }
    }
  }
}

.tm-os-sec {
  padding: 60px 0;

  h2 {
    font-weight: 500;
    font-size: 26px;
    color: #333;
    margin-bottom: 20px;
  }

  h3 {
    font-weight: 500;
    font-size: 20px;
    color: #333;
    margin-bottom: 16px;
  }
}

.cc-item {
  max-width: 879px;
}

.pd-sec {
  .pd-text {
    font-size: 18px;
    opacity: 0.6;
  }

  ul.green-list {
    @include media($sm - 1, max) {
      margin: 0 auto;
      gap: 0.9rem;

      li {
        padding-left: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        gap: 10px;
        font-size: 14px;

        &::before {
          position: inherit;
        }
      }
    }
  }
}

.hc-sec .hc-text {
  font-size: 26px;
  color: #e5e5e5;
}

.footer {
  background-color: #16213F;
  color: $white;
  padding: 50px 0;
  // margin-top: 40px;
  color: #fff;

  @include media($lg) {
    // margin-top: 80px;
    padding: 128px 0;
  }

  p {
    opacity: 0.65;
    font-weight: normal;
    // font-size: 14px;
    // letter-spacing: 0.02em;
  }

  h2 {
    font-size: 16px;
    opacity: 0.65;
  }

  .nav-link {
    color: $white;
    font-size: 14px;

    @include media($md) {
      font-size: 18px;
    }

    &:hover {
      color: $primary-color;
    }
  }
}

.social-media {
  .btn {
    @include media($sm) {
      padding: 8px;
    }
  }
}

.whatsapp-iocn {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 40px;
  height: 40px;
  display: inline-block;
  background: url("../images/whatsapp-icon.png") center no-repeat;
  background-size: contain;
  overflow: hidden;
  text-indent: -99999px;
  z-index: 1;
}

.services-list {
  font-weight: normal;
  font-size: 18px;
  line-height: 1.4;
  text-align: left;
  color: $halfBlack;
  padding-left: 0;
  // margin-top: 20px;

  @include media($sm) {
    font-size: 22px;
  }

  @include media($lg) {
    font-size: 28px;
  }

  > li {
    color: $white;

    b {
      font-weight: bold;
    }

    &:not(:last-child) {
      margin-bottom: 10px;

      @include media($sm) {
        margin-bottom: 20px;
      }
    }

    position: relative;
    list-style: none;
    padding-left: 32px;

    &::before {
      content: "";
      display: inline-block;
      width: 10px;
      height: 10px;
      background: $primary-color;
      border-radius: 50%;
      position: absolute;
      top: 7px;
      left: 0;

      @include media($sm) {
        top: 9px;
      }

      @include media($lg) {
        top: 18px;
      }
    }
  }

  &.sm {
    font-weight: 600;
    font-size: 16px;
    color: #666;

    @include media($sm) {
      font-size: 21px;
    }

    li {
      color: #666;
      margin-bottom: 10px;

      &::before {
        top: 8px;
      }
    }
  }
}

// .amc-sec {
//   background-color: #f5e9e9;
//   padding: 75px 0;
// }

.services-para {
  // font-weight: 600;
  font-size: 18px;
  line-height: 1.4;
  color: $halfBlack;
  // margin-bottom: 58px;
  margin-left: 32px;

  @include media($sm) {
    font-size: 22px;
  }

  @include media($lg) {
    font-size: 28px;
  }

  @include media($sm, max) {
    padding-top: 10px;
  }
}

@import "core/variables";
@import "core/mixins";
@import "core/fonts";
@import "core/common";
@import "nav";
@import "masthead";
@import "services";
@import "our-services";
@import "how-it-works";
@import "plans-and-pricing";
@import "help-us-to-help-you";
@import "masthead-tm";
@import "holistic-view";
@import "case-studies";
@import "about-us";
@import "mohandas-masthead";
// @import "circle-animation";
// @import "client-testimonials";
@import "footer";

// @import "about-us";


.mohan-box {
  position: relative;

  .ma-inner {
    width: 413px;
    // height: 122px;
    border-radius: 16px 16px 0px 0px;
    background: #0078ca;
    box-shadow: 0px 6px 10px rgba(152, 152, 152, 0.16);
    padding: 20px;
    color: $white;
    text-align: center;
    // margin-right: 30px;
    position: absolute;
    bottom: 0;
    right: 30px;

    h3 {
      font-weight: 500;
      font-size: 20px;
      color: $white;

      @include media($md) {
        font-size: 30px;
      }

      @include media($lg) {
        font-size: 40px;
      }
    }

    p {
      font-weight: 500;
      // font-size: 20px;
      opacity: 0.8;

      @include media($md) {
        font-size: 18px;
      }

      @include media($lg) {
        font-size: 20px;
      }
    }
  }
}

.interview-block {
  h2 {
    font-weight: 600;
    font-size: 21px;
    letter-spacing: -0.01em;
    margin-bottom: 20px;

    @include media($md) {
      font-size: 24px;
    }

    @include media($lg) {
      font-size: 27px;
    }
  }

  p {
    font-size: 18px;
    color: #666;

    @include media($md) {
      font-size: 20px;
    }

    @include media($lg) {
      font-size: 24px;
    }
  }
}

.interview-video {
  position: relative;

  .play-icon {
    position: absolute;
    width: 90px;
    height: 90px;
    background: url("../images/play-icon.svg") center center no-repeat;
    background-size: contain;
    top: 50%;
    left: 50%;
    font-size: 0;
    transform: translate(-50%, -50%);
  }
}

.services-block {
  h2 {
    font-weight: normal;
    font-size: 22px;
    color: #666;

    @include media($md) {
      font-size: 28px;
    }

    @include media($lg) {
      font-size: 34px;
    }
  }

  p {
    font-weight: 600;
    // font-size: 22px;
    line-height: 2;
    color: #666;

    @include media($md) {
      font-size: 20px;
    }

    @include media($lg) {
      font-size: 24px;
    }
  }
}

.patent-block {
  h2 {
    font-weight: 600;
    font-size: 22px;
    color: #333;
    margin-top: 10px;

    @include media($md) {
      font-size: 28px;
    }

    @include media($lg) {
      font-size: 34px;
    }
  }

  p {
    // font-size: 24px;
    color: #666;

    @include media($md) {
      font-size: 20px;
    }

    @include media($lg) {
      font-size: 24px;
    }
  }

  p.q-textarea {
    font-weight: normal;
    font-style: italic;
    font-size: 18px;
    color: #666;

    @include media($md) {
      font-size: 22px;
    }

    @include media($lg) {
      font-size: 28px;
    }
  }

  p.ver-text {
    font-weight: 500;
    color: #333;
  }

  h3 {
    font-weight: 600;
    font-size: 20px;
    color: #333;

    @include media($lg) {
      font-size: 24px;
    }
  }
}

.b-title {
  font-weight: 600;
  font-size: 22px;
  color: #333;
  margin-top: 10px;

  @include media($md) {
    font-size: 28px;
  }

  @include media($lg) {
    font-size: 34px;
  }
}

.investments-block {
  .invt-text {
    font-size: 20px;
    text-align: center;
    color: #333;

    @include media($md) {
      font-size: 24px;
    }
  }
}

.ib-card {
  border-radius: 20px;
  background: #f1f8fe;
  padding: 50px 30px;

  img {
    height: 64px;
  }

  h3,
  p {
    font-size: 18px;
    color: #333;

    @include media($md) {
      font-size: 24px;
    }
  }

  h3 {
    font-weight: 600;
  }
}

.mcs-block {
  .cs-item {
    padding: 0;
    background-color: transparent;
  }
}

.testi-item {
  padding-top: 30px;
  h3 {
    font-weight: 500;
    font-style: italic;
    font-size: 15px;
    letter-spacing: -0.01em;
    line-height: 1.5;
    color: #000;
    margin-bottom: 20px;
    // padding-left: 50px;
    position: relative;

    @include media($sm) {
      font-size: 18px;
      line-height: 32px;
    }

    &::before {
      content: "";
      width: 36px;
      height: 28px;
      background: url("../images/quote-mark-green.svg") center no-repeat;
      background-size: 100%;
      display: inline-block;
      position: absolute;
      top: -6px;
      left: -45px;
    }
  }
}

.testi-author {
  display: table;

  > div {
    vertical-align: middle;

    @include media(732px) {
      display: table-cell;
    }
  }

  .author-photo {
    width: 102px;
    height: 102px;
    border-radius: 50%;

    @include media($sm, max) {
      width: 90px;
      height: 90px;
      margin: 0 auto 15px;
    }
  }

  .author-name {
    padding-left: 20px;

    p {
      font-size: 15px;
      line-height: 1.5;
      color: #333;

      @include media($sm) {
        font-size: 18px;
        line-height: 32px;
      }

      + p {
        color: #666;
        line-height: 1.4;
      }
    }
  }
}

.testimonials-sec {
  background: #f3f9fd;
  padding: 50px 0;
  margin-top: 40px;
  position: relative;

  @include media($sm, max) {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.testimonials-inner {
  max-width: 946px;
  margin: 0 auto;
  position: relative;
  z-index: 1;

  @include media($sm, max) {
    padding: 0 15px;
  }
}

.testimonials-slider {
  .slick-dots li {
    width: auto;
    height: auto;
  }

  .slick-dots li button {
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background: #d9e7f0;
    border-color: #d9e7f0;
    transition: all 0.3s ease-in;
  }

  .slick-dots li.slick-active button {
    width: 30px;
    background: #0278ca;
  }

  .slick-dots li button::before {
    display: none;
  }
}

.testi-item-inner {
  padding-left: 45px;
  padding-top: 10px;
}

.lets-talk-block {
  h3 {
    font-weight: 600;
    font-size: 21px;
    color: #333333;
  }

  .link {
    color: #666;
    text-decoration: none;
  }
}

.mcs-slider {
  /* the slides */
  .slick-slide {
    margin: 0 35px;
  }

  /* the parent */
  .slick-list {
    margin: 0 -35px;
  }

  .slick-dots li button {
    background: #d9e7f0;
    border-color: #d9e7f0;
    transition: all 0.3s ease-in;
  }

  .slick-dots li.slick-active button {    
    background: #0278ca;
  }
}

.masthead-images {
  @include media($lg) {
    max-width: 550px;
    margin-left: auto;
  }
}

.masthead-tm {
  border-top: 1px solid #c7c7c7;
  padding-top: 30px;

  &::after {
    content: "";
    width: 100%;
    height: 80px;
    background-color: #f1f8fe;
    display: block;
    margin-top: -80px;
  }
  .main-sub-title {
    @include media($lg) {
      max-width: 80%;
    }
  }
}

.mi-blue {
  //   width: 231px;
  height: 183px;
  border-radius: 16px;
  background: #0078ca;
  box-shadow: 0px 6px 10px rgba(152, 152, 152, 0.16);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px;

  .mi-value {
    font-weight: 500;
    font-size: 40px;
    line-height: 1.2;
    color: #fff;

    // @include media($sm) {
    //   font-size: 50px;
    // }

    @include media($lg) {
      font-size: 50px;
    }
  }

  .mi-name {
    font-weight: 300;
    font-size: 18px;
    line-height: 1.3;
    color: #fff;

    @include media($lg) {
      font-size: 30px;
      line-height: 1.1;
    }
  }
}

.mi-white {
  //   width: 231px;
  height: 220px;
  border-radius: 16px;
  background: #fff;
  border: 1px solid #c7c7c7;
  box-shadow: 0px 6px 10px rgba(152, 152, 152, 0.16);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
  margin-top: 30px;

  @include media($md) {
    padding: 20px;
  }

  .mi-value {
    font-weight: 500;
    font-size: 30px;
    line-height: 1.2;
    color: #000;

    @include media($sm) {
      font-size: 40px;
    }
  }

  .mi-name {
    font-weight: 300;
    font-size: 16px;
    line-height: 1.1;
    color: #000;

    @include media($sm) {
      font-size: 24px;
    }
  }
}

.mi-image {
  width: 100%;
  height: 237px;
  border-radius: 16px;
  border: 1px solid #c7c7c7;
  box-shadow: 0px 6px 10px rgba(152, 152, 152, 0.16);
  overflow: hidden;
  object-fit: cover;
}

.miw-2 {
  width: 100%;
  height: 164px;
  padding: 20px 30px;
}

.tm-sec {
  background: #f1f8fe;
  padding-bottom: 60px;

  @include media($lg, max) {
    padding: 30px 0;
  }

  h2 {
    font-weight: 600;
    font-size: 30px;
    color: #333;
    max-width: 662px;

    @include media($md) {
      font-size: 36px;
    }
  }

  p {
    font-size: 20px;
    color: #666;
    max-width: 1068px;
    margin-top: 28px;

    @include media($md) {
      font-size: 26px;
    }
  }

  h3 {
    font-weight: 500;
    font-size: 20px;
    color: #333;
    margin-top: 40px;
    margin-bottom: 20px;

    @include media($md) {
      font-size: 26px;
    }
  }
}

.masthead-mt {
  .main-sub-title {
    @include media($lg) {
      max-width: 100%;
      font-size: 60px;
      line-height: 1.3;
    }
  }
}

.cs-item {
  padding: 24px 0;

  @include media($md) {
    padding: 50px 0;
  }

  &:nth-child(odd) {
    background: #f1f8fe;
  }

  h2 {
    font-weight: 500;
    font-size: 22px;
    color: #333;

    @include media($sm) {
      font-size: 26px;
    }

    @include media($md) {
      font-size: 30px;
    }

    @include media($lg) {
      font-size: 36px;
    }
  }

  p {
    font-weight: normal;
    font-size: 16px;
    color: #666;

    @include media($sm) {
      font-size: 18px;
    }

    @include media($md) {
      font-size: 22px;
    }

    @include media($lg) {
      font-size: 26px;
      margin-top: 20px;
    }
  }

  ul {
    display: flex;
    margin-top: 30px;
    padding-left: 0;
    // gap: 30px;

    li {
      display: flex;
      flex-direction: column;
      text-align: center;
      padding: 0 30px;

      &:not(:last-child) {
        border-right: 1px dashed #707070;
      }

      span {
        &:first-child {
          font-weight: 500;
          font-size: 20px;
          line-height: 1;
          text-align: center;
          color: #333;

          @include media($sm) {
            font-size: 22px;
          }

          @include media($md) {
            font-size: 24px;
          }

          @include media($lg) {
            font-size: 26px;
          }
        }

        &:last-child {
          font-weight: normal;
          font-size: 12px;
          color: #666;

          @include media($sm) {
            font-size: 14px;
          }

          @include media($md) {
            font-size: 16px;
          }
        }
      }
    }
  }
}

.csc-masthead {
  padding: 24px 0 0;

  @include media($md) {
    padding: 50px 0 0;
  }

  h2 {
    font-weight: 600;
    font-size: 30px;
    color: #333;

    @include media($sm) {
      font-size: 35px;
    }

    @include media($md) {
      font-size: 40px;
    }

    @include media($lg) {
      font-size: 45px;
    }
  }

  p {
    font-weight: normal;
    font-size: 16px;
    color: #666;

    @include media($sm) {
      font-size: 18px;
    }

    @include media($md) {
      font-size: 22px;
    }

    @include media($lg) {
      font-size: 26px;
      margin-top: 20px;
    }
  }
}

.csc-item {
  padding: 24px 0;

  @include media($md) {
    padding: 50px 0;
  }

  &:nth-child(odd) {
    .csc-border-start {
      @include media($md) {
        border-left: 7px solid #f1f8fe;
        padding-left: 35px;
      }
    }
  }

  &:nth-child(even) {
    background: #f1f8fe;

    .csc-border-start {
      @include media($md) {
        border-left: 7px solid #ffffff;
        padding-left: 35px;
      }
    }
  }

  h2 {
    font-weight: 600;
    font-size: 18px;
    color: #333;

    @include media($md) {
      font-size: 22px;
    }

    @include media($lg) {
      font-size: 26px;
    }
  }

  h3 {
    font-weight: 400;
    font-size: 16px;
    color: #333;

    @include media($md) {
      font-size: 18px;
    }

    @include media($lg) {
      font-size: 20px;
    }
  }

  p {
    // font-size: 20px;
    line-height: 1.5;
    color: #666;

    @include media($md) {
      font-size: 18px;
    }

    @include media($lg) {
      font-size: 20px;
    }
  }
}

.suggest-sec {
  background-color: #071232;
  padding: 20px;

  @include media($md) {
    padding: 40px;
  }

  .p-text {
    position: relative;  
    padding-top: 30px;  
     
    &::before {
      content: " ";
      display: block;
      width: 36px;
      height: 36px;
      background: url("../images/quote-mark-white.svg") center center no-repeat;
      position: absolute;
      top: 0;
      left: 50%;
    }
  }
}

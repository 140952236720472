.our-services-sec {
  padding: 150px 0 40px;
  background-color: #F1F8FE;

  @include media($md) {
    padding: 120px 0;
  }
}

.our-services-block {
  max-width: 870px;
  margin: 40px auto 0;
}

.services-card {
  margin-bottom: 40px;

  // .card-body {
  //   padding-top: 40px;
  // }
  .card-title {
    font-weight: bold;
    font-size: 20px;    
    color: $black;
    margin-bottom: 20px;
  }

  p {
    font-weight: normal;
    font-size: 14px;
    line-height: 23px;
    color: #5D6180;
  }
}

.book-call-block {
  margin-top: 70px;
  padding-top: 30px;
  border-top: 1px solid #CECFD3;

  h3 {
    font-style: 30px;
    font-weight: bold;
    color: $halfBlack;
  }

  p {
    color: #5D6180;
  }

  .btn-call {
    background-color: $primary-color;
    border-color: $primary-color;
  }
}

.plans-and-pricing-sec {
  padding: 150px 0 40px;
  background-color: #16213F;
  // color: $white;

  @include media($md) {
    padding: 120px 0;
  }
}

.pap-block {
  max-width: 920px;
  margin: 40px auto 0;
}

.pap-card {
  // margin-bottom: 40px;
  border-radius: 20px;
  padding: 30px 30px 20px;
  border: 1px solid $primary-color;
  height: 100%;

  // .card-body {
  //   padding-top: 40px;
  // }
  .card-title {
    font-weight: 600;
    font-size: 18px;    
    color: $primary-color;
    margin-bottom: 20px;
  }

  p {
    font-weight: 600;
    line-height: 1.4;
    color: #5D6180;
  }

  ul {
    font-size: 14px;
    color: #5D6180;
    padding: 0;
    list-style: none;

    li {
      position: relative;
      padding-left: 29px;
      margin-bottom: 20px;
      font-size: 14px;

      &::before {
        content: "";
        display: inline-block;
        position: absolute; 
        left: 0;      
        width: 19px;
        height: 19px;
        
        background: url("../images/tick-check.png") center no-repeat;
        background-size: contain;
    
        // @include media($md) {
        //   bottom: -139px;
        //   right: -5px;
        //   width: 110px;
        //   height: 110px;
        // }
      }
    }
  }

  .btn-link {
    text-decoration: none;
    color: #161C2D;
    display: inline-block;
  }
}